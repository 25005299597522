.footer {
    display: flex;
    align-items: flex-end;    
}

.gold {
    color: gold;
}

.headerContainer{
    font-size: 3em;
    width: 40%;
    align-self: center;
}
.aboutAuthorContainer{
    background-color: black;
    width:50%;
    display: flex;
    align-self: flex-end;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: row;
}
.aboutAuthorText{
    text-align: left;
    padding: 25px;
}
.aboutAuthorText p{
    font-size: 20px
}
.authorPhoto{
    height: 370px;
}
@media screen and (max-width: 1600px) {
    .headerContainer{
        font-size: 2.5em;
    }
}

@media screen and (max-width: 1200px) {
    .footer{
        padding-top: 170px;
        flex-direction: column;
    }
    .headerContainer {
        font-size: 2.5em;
        width: 80%;
    }
    .aboutAuthorContainer{
        width: 100%;
    }
    .aboutAuthorText {
        align-self: flex-start;
    }

}

@media screen and (max-width: 666px) {
        .aboutAuthorContainer{
            flex-direction: column
        }

        .authorPhoto{
            height: 50vh;
            
        }
        .headerContainer {
            font-size: 1.8em;
            width: 90%;
        }
        .aboutAuthorText{
            padding: 20px;
            
        }
}