.homepage {
  /* scroll-behavior: smooth; */
  width: 100%;
  overflow: auto;
  scroll-snap-type: y mandatory;
  height: 100vh;
}

.mainSection {
    display: flex;
    flex-direction: row;
    text-align: left;
    justify-content: space-around;
    align-items: center;
    background: black;
    
    height:100vh;
    font-family: "Share Tech Mono", monospace;
    font-weight: 600;
    color: white;
    scroll-snap-align: start;
  
}
  
.typpingAnimationContainer {
    padding: 20px;
    width: 40%;
    height: auto;
    align-self: flex-start;
}
.typpingAnimationContainer a{
  text-decoration: none;
  color: rgb(0, 140, 255);
}
  
.profilePic {
    
  width:30vw;
  align-self: flex-end;
   margin-bottom: 20px; 
}  
.dataPhoto {
  height: 63vh;
  
}
  
.redTyped {
    color: #00FF00;
}

.rootTyped {
  color: rgb(215, 54, 215);
}
.link:hover{
  color:gold;
  transition: color 0.5s ;
}


@media screen and (max-width: 1200px) {
 
  .homepage{
    scroll-snap-type: none;
    height: auto;
  }


}

